/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { styled } from '@superset-ui/core';
import { Radio as AntdRadio } from 'antd';

const StyledRadio = styled(AntdRadio)`
	.ant-radio-inner {
		top: -1px;
		left: 2px;
		width: ${({ theme }) => theme.gridUnit * 4}px;
		height: ${({ theme }) => theme.gridUnit * 4}px;
		border-width: 2px;
		border-color: ${({ theme }) => theme.colors.grayscale.light2};
	}

	.ant-radio.ant-radio-checked {
		.ant-radio-inner {
			border-width: ${({ theme }) => theme.gridUnit + 1}px;
			border-color: ${({ theme }) => theme.colors.primary.base};
		}

		.ant-radio-inner::after {
			background-color: ${({ theme }) => theme.colors.grayscale.light5};
			top: 0;
			left: 0;
			width: ${({ theme }) => theme.gridUnit + 2}px;
			height: ${({ theme }) => theme.gridUnit + 2}px;
		}
	}

	.ant-radio:hover,
	.ant-radio:focus {
		.ant-radio-inner {
			border-color: ${({ theme }) => theme.colors.primary.dark1};
		}
	}
`;
const StyledGroup = styled(AntdRadio.Group)`
	font-size: inherit;
`;

export const Radio = Object.assign(StyledRadio, {
	Group: StyledGroup,
});
